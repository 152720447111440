import Modal from "components/Modal"
import CustomComponent from 'components/customComponent';
import { Link, withRouter } from "react-router-dom"
import { FormSwitch, FormCheckBox, FormInput } from "components/FormComponents"
import {validateNumber, validatePdf} from '../../helpers/validation';
import SlideDown from "react-slidedown";
import hoistStatics from 'hoist-non-react-statics';
import { withTranslation, useTranslation } from 'react-i18next';
import { withToast } from '../../App';
import LoadingOverlay from "react-loading-overlay";
import { useState } from 'react';
import { isArray } from "lodash";
import classNames from "classnames";
import {matchRoles} from "../../helpers/helpers";
const DEFAULT_COLS = 2

/**
 * Merges the given connectors with metadata.
 *
 * @param {Array} connectors - The connectors to merge.
 * @param {Object} metadata - The metadata containing connector information.
 * @returns {Array} - The merged connectors with metadata.
 */
const mergeConnectorsWithMetadata = (connectors, metadata) => connectors.map(c => {
    const connectorMetadata = metadata.connectors.find(cm => cm.connectorId === +c.cpoConnectorId)
    return connectorMetadata
        ? {
            ...c,
            label: connectorMetadata.label,
            row: connectorMetadata.row,
            col: connectorMetadata.col
        }
        : null
}).filter(v => v !== null)

/**
 * Checks if the given connectors are using rows and columns.
 *
 * @param {Array} connectors - The connectors to check.
 * @returns {boolean} - True if the connectors are using rows and columns, false otherwise.
 */
const isUsingRowsAndCols = (connectors) => !connectors.some(({ row, col }) => row === undefined || col === undefined)

/**
 * Checks if the rows and columns of the connectors are unique.
 *
 * @param {Array} connectors - The array of connectors.
 * @returns {boolean} - True if the rows and columns are unique, false otherwise.
 */
const areRowsAndColsUnique = (connectors) => connectors
    .map(({ row, col }) => `${row}:${col}`)
    .filter((v, i, a) => i === a.indexOf(v))
    .length === connectors.length

/**
 * Fixes connectors without row and col properties.
 *
 * @param {Array} connectors - The array of connectors to fix.
 * @returns {Array} - The fixed array of connectors.
 */
const fixConnectorsWithoutRowAndCol = (connectors) => connectors
    .slice()
    .sort((a, b) => a.position - b.position)
    .map(c => ({
        ...c,
        col: (c.position - 1) % 2,
        row: Math.floor((c.position - 1) / 2)
    }))

/**
 * Checks if the metadata is valid.
 *
 * @param {Object} metadata - The metadata object to validate.
 * @returns {boolean} - Returns true if the metadata is valid, false otherwise.
 */
const isMetadataValid = (metadata) => metadata?.connectors && isArray(metadata.connectors) && metadata.connectors.length > 0

/**
 * Returns a function that takes an array of connectors and extracts a value from each connector using the provided extractor function.
 * The extracted values are then reduced to find the maximum value.
 *
 * @param {Function} extractor - The function used to extract a value from each connector.
 * @returns {Function} - A function that takes an array of connectors and returns the maximum extracted value.
 */
const getMax = (extractor) => (connectors) => connectors.map(extractor).reduce((acc, cur) => Math.max(acc, cur), 0)

/**
 * Retrieves the metadata for a terminal.
 *
 * @param {string} terminalId - The ID of the terminal.
 * @param {Function} request - The request function used to make the API call.
 * @returns {Promise<Object>} - A promise that resolves to the metadata object.
 */
const getMetadata = async (terminalId, request) => {
    const metadataUrl = `/cpo/chargeboxmetadata/${terminalId}`

    return await request(metadataUrl, 'GET', {
        isHandlingError: (_, httpStatus) => httpStatus === 404,
        handleError: () => ({})
    })
}

/**
 * Represents a modal component for a terminal.
 *
 * @class TerminalModal
 * @extends CustomComponent
 */
class TerminalModal extends CustomComponent {
    constructor(props) {
        super(props);
        this.state = {
            eni: '',
            code: '',
            display: "LIST", // LIST, DETAILS, CONNECTOR, CHARGE
            terminal: props?.terminal ?? {},
            connector: props?.terminal?.connector || null,
            count: {},
            isLoading: true,
            manual_url: '',
            manual_upload_input_value: '',
            error: null,
        }
        this._isMounted = false
        this._interval = null
    }

    componentDidMount() {
        this._isMounted = true
        this.getTerminal()
    }

    componentWillUnmount() {
        this._isMounted = false
        if (this._interval) clearInterval(this._interval)
    }

    /**
     * Retrieves the terminal data from the server.
     *
     * @param {boolean} withMetadata - Flag indicating whether to include metadata in the response.
     * @returns {Promise<Object|null>} - A promise that resolves to the terminal data or null if not found.
     */
    getTerminal = async (withMetadata = true) => {
        this.setState({ isLoading: true });

        const terminalUrl = `/terminal/${this.props.terminal.id}`
        const method = "GET"
        const [data, metadata] = await Promise.all([
            this.state?.connector || this.request(terminalUrl, method),
            withMetadata
                ? getMetadata(
                    this.props.terminal.cpoTerminalId,
                    (url, method, errorHandler) => this.request(url, method, '', false, false, errorHandler)
                )
                : {}
        ])

        const connectors = withMetadata && data?.terminal && isMetadataValid(metadata)
            ? mergeConnectorsWithMetadata(data.terminal.connectors, metadata)
            : data.terminal.connectors

        const fixedConnectors = isUsingRowsAndCols(connectors) && areRowsAndColsUnique(connectors)
            ? connectors
            : fixConnectorsWithoutRowAndCol(connectors)

        data.terminal.connectors = fixedConnectors

        if (this._isMounted && data && data.status === 'ok' && data.terminal) {
            this.setState({
                count: data.count,
                terminal: data.terminal,
                manual_url: data.manual_url,
                isLoading: false
            })
            return data.terminal
        }

        this.setState({ isLoading: false })
        return null
    }

    /**
     * Handles the logic after a transaction is successful.
     * Updates the display state to 'CHARGE' and starts an interval to check for changes in connector status.
     * If the connector status has changed and is not 'Preparing', it calls the onChargeMessageClose function.
     *
     * @returns {Promise<void>} A promise that resolves when the logic is complete.
     */
    afterTransactionSuccess = async () => {
        this.setState({ display: 'CHARGE' })
        const { id, status } = this.state.connector

        if (this._interval) clearInterval(this._interval)
        this._interval = setInterval(async () => {

            const terminal = await this.getTerminal()
            if (terminal) {
                const connector = terminal.connectors.find(c => c.id === id)

                // If connector status has changed
                if (connector && connector.status !== status && connector.status !== 'Preparing') {
                    this.onChargeMessageClose()
                }
            }
        }, 2500)
    }

    /**
     * Closes the charge message and sets the display state to 'LIST'.
     */
    onChargeMessageClose = () => {
        if (this._interval) clearInterval(this._interval)
        this.setState({ display: 'LIST' })
    }

    /**
     * Toggles the display of the connector and updates the state accordingly.
     *
     * @param {Event} e - The event object.
     * @param {string} connector - The connector value.
     */
    onToggle = (e, connector) => {
        e.preventDefault()
        this.setState({
            display: 'CONNECTOR',
            connector: connector
        })
    }

    /**
     * Handles the submission of the form.
     *
     * @param {Event} e - The event object.
     * @returns {Promise<void>} - A promise that resolves when the submission is complete.
     */
    afterSubmission = async (e) => {
        e.preventDefault()

        this.afterTransactionSuccess()

        const { t } = this.props
        const { status, id } = this.state.connector

        let url = `/terminal/charge/${status === 'Charging' ? 'stop' : 'start'}/${id}`;
        let csrf = await this.getCsrfToken("/terminal/csrf");
        const method = "POST";
        let body = JSON.stringify({
            _csrf_token: csrf,
            boat_eni: this.state.eni,
            boat_accesscode: this.state.code,
        });

        let data = await this.request(url, method, body);

        if (data && data.status === 'ok') {
            const message = status !== "Charging" ? 'transaction_start_success' : 'transaction_stop_success'
            this.props.addToast(t(message), { appearance: 'success', autoDismiss: true, autoDismissTimeout: 4000 });
            this.afterTransactionSuccess()
        }
    }

    /**
     * Handles the event when reporting a terminal as unavailable or available.
     *
     * @param {Event} e - The event object.
     * @param {Object} connector - The connector object containing the status and id.
     * @returns {Promise<void>} - A promise that resolves when the request is completed.
     */
    onReportUnvailable = async (e, connector) => {
        e.preventDefault()

        const { status, id } = connector
        const { t } = this.props

        let url = `/terminal/${status === 'Unavailable' ? 'enable' : 'disable'}/${id}`;
        let csrf = await this.getCsrfToken("/terminal/csrf");
        const method = "POST";
        let body = JSON.stringify({
            _csrf_token: csrf,
        });
        let data = await this.request(url, method, body);
        if (data && data.status === 'ok') {
            let message = status === 'Unavailable' ? 'report_available_success' : 'report_unavailable_success'
            this.props.addToast(t(message), { appearance: 'success', autoDismiss: true, autoDismissTimeout: 4000 });
        }
    }

    openManual = () => {
        const {t} = this.props;

        if (this.state.manual_url) {
            try {
                window.open(this.state.manual_url, '_blank').focus();
            } catch (_e) {
                this.props.addToast(t('blocked_file_popup'), {appearance: 'warning'});
            }
        }
    }

    uploadManual = async (e) => {
        const { t } = this.props;
        const fileList = e?.target?.files;

        if (fileList?.length > 0) {
            const validation = validatePdf(e);
            this.setState({
                error: validation,
                manual_upload_input_value: e?.target?.value || '',
            });

            if (validation) {
                this.setState({manual_upload_input_value: ''});
                return;
            }

            const body = new FormData();
            const csrf = await this.getCsrfToken('/terminal/csrf');
            body.append('manual_file', fileList[0]);
            body.append('_csrf_token', csrf);

            this.setState({isLoading: true});
            const data = await this.request(`/terminal/${this.props.terminal.id}/manual`, 'POST', body, true);
            this.setState({
                manual_upload_input_value: '',
                isLoading: false,
            });

            if (data?.status === 'ok' && this._isMounted) {
                this.props.addToast(t('terminal_manual_update_success'), { appearance: 'success', autoDismiss: true, autoDismissTimeout: 4000 });

                if (!this.state.manual_url) {
                    this.setState({
                        terminal: data?.terminal,
                        manual_url: data?.manual_url,
                    });
                }
            }
        }
    }

    render() {
        const { onClose, t } = this.props;
        const { terminal } = this.state;
        terminal.price_group = 'Borne&Eau';
        const userRoles = this.loadRoles();

        const connectors = terminal.connectors.sort((a,b) => a.position - b.position)
        const maxCol = connectors
            ? getMax(c => c.col)(connectors) + 1
            : DEFAULT_COLS

        return (
            <LoadingOverlay active={this.state.isLoading} classNamePrefix="loader_" spinner text={t('loading')}>
                <Modal size={`lg-${maxCol}`} onClose={onClose} open={!this.state.isLoading} title={terminal.cpo_terminal_id} extraClasses={{
                    content: 'modal__content__connector',
                    description: 'modal__description__connector',
                    body: 'modal__body__connector'
                }}>
                    <div className="d-flex justify-content-between align-items-center flex-column flex-md-row">
                        <p className="modal__description order-1 order-md-2 modal__description__connector">
                            {t('place')} : {terminal.address} {terminal.city} <br />
                            {t('name')} : {terminal.cpoTerminalId}
                        </p>

                        <button
                            type="button"
                            onClick={this.openManual}
                            className={classNames(
                                {hidden: !this.state.manual_url},
                                'vnf-btn vnf-btn-m vnf-btn-outline vnf-btn-outline-icon-label vnf-btn-bleu-a2 order-2 order-md-1 mb-2 mb-md-0'
                            )}
                        >
                            <i className="vnf-icons-icon-m-document-outline"></i>
                            {t('open_terminal_manual')}
                        </button>

                        <div className="order-3">
                            <label
                                htmlFor={`${this.props.terminal.id}-upload`}
                                className={classNames(
                                    {hidden: !matchRoles(userRoles, ['ROLE_ADMIN'])},
                                    'vnf-btn vnf-btn-m vnf-btn-outline vnf-btn-outline-icon-label vnf-btn-bleu-a2'
                                )}
                            >
                                <input
                                    type="file"
                                    accept="application/pdf, .pdf"
                                    name="manual_upload"
                                    id={`${this.props.terminal.id}-upload`}
                                    aria-labelledby={`${this.props.terminal.id}-upload-label`}
                                    className="d-none"
                                    onChange={this.uploadManual}
                                    value={this.state.manual_upload_input_value}
                                />
                                <i className="vnf-icons-icon-m-upload-outline"></i>
                                <span id={`${this.props.terminal.id}-upload-label`}>
                                    {this.state.manual_url ? t('replace_terminal_manual') : t('import_terminal_manual')}
                                </span>
                            </label>
                            {this.state.error && (
                                <div className={`helper helper--absolute helper--primary helper--primary--${this.state.error.type}`}>
                                    <i className={`vnf-icons-icon-s-${this.state.error.type}-solid`}></i>
                                    {t(this.state.error.message)}
                                </div>
                            )}
                        </div>
                    </div>

                    {this.state.display === 'DETAILS' && (<>
                        <div className="terminal-details terminal-details--top">
                            <h2 className="terminal-details__title">{t('transaction_count')}</h2>
                            <ul className="terminal-details__transactions">
                                <li><span>{t('total')}</span>{this.state.count['total']}</li>
                                <li><span>{t('this_week')}</span>{this.state.count['week']}</li>
                                <li><span>{t('today')}</span>{this.state.count['day']}</li>
                            </ul>
                        </div>
                        <div className="terminal-details terminal-details--bottom">
                            <h2 className="terminal-details__title">{t('terminal_details')}</h2>
                            <ul className="terminal-details__info">
                                <li>GPS : LAT: {terminal.gpsLat} | LNG {terminal.gpsLon}</li>
                                <li>{t('price_group')} : {terminal.price_group}</li>
                            </ul>
                        </div>

                        <div className="modal__buttons modal__buttons--right w-100">
                            <button type="button" onClick={() => this.setState({ display: 'LIST' })} className="vnf-btn vnf-btn-primary vnf-btn-m vnf-btn-multi-color vnf-btn-vert-b4 vnf-btn-box-shadow-vert-b1">Retour</button>
                        </div>
                    </>)}
                    {this.state.display === 'LIST' && (<>
                        <ConnectorLayout connectors={connectors} terminal={terminal} onToggle={this.onToggle} onReportUnvailable={this.onReportUnvailable} />
                        <div className="modal__buttons modal__buttons--right w-100 modal__buttons__connector">
                            <button type="button" onClick={() => this.setState({ display: 'DETAILS' })} className="vnf-btn vnf-btn-primary vnf-btn-m vnf-btn-multi-color vnf-btn-vert-b4 vnf-btn-box-shadow-vert-b1">Détails</button>
                            <Link to={`/transaction/terminal/${terminal.cpoTerminalId}`} className="vnf-btn vnf-btn-primary vnf-btn-m vnf-btn-multi-color vnf-btn-vert-b4 vnf-btn-box-shadow-vert-b1">Transactions</Link>
                        </div>
                    </>)}
                    {this.state.display === 'CONNECTOR' && (<div className="primary-form">
                        <h2 className="primary-form__title h2">{this.state.connector.status !== "Charging" ? t('start_a_distribution') : t('stop_a_distribution')}</h2>
                        <p className="primary-form__description">{t('connector')} : {t(this.state.connector.fluidType)}-{this.state.connector.position}</p>
                        <form onSubmit={(e) => this.afterSubmission(e)}>
                            <div className="row">
                                <div className="col-sm-12">
                                    <FormInput
                                        label={t("eni")}
                                        type="text"
                                        name="eni"
                                        id="eni"
                                        validation={validateNumber}
                                        handle={(e) => this.setState({ eni: e.target.value })}
                                        value={this.state.eni} />
                                </div>
                                {this.state.connector.status !== "Charging" && (
                                    <div className="col-sm-12">
                                        <FormInput
                                            label={t("code")}
                                            type="text"
                                            name="code"
                                            id="code"
                                            validation={validateNumber}
                                            handle={(e) => this.setState({ code: e.target.value })}
                                            value={this.state.code} />
                                    </div>
                                )}
                            </div>
                            <hr className="form-divider form-divider--big" />
                            <div className="primary-form__buttons">
                                <button type="button" onClick={() => this.setState({ display: 'LIST' })} className="vnf-btn vnf-btn-secondary vnf-btn-m vnf-btn-outline vnf-btn-bleu-a3">{t('cancel')}</button>
                                <button type="submit" className="vnf-btn vnf-btn-primary vnf-btn-m vnf-btn-similar-color vnf-btn-bleu-a2 vnf-btn-box-shadow-bleu-a4">{t('Save')}</button>
                            </div>
                        </form>
                    </div>)}
                    {this.state.display === "CHARGE" && (<div className="primary-form">
                        <h2 className="primary-form__title h2">{this.state.connector.status !== "Charging" ? t('start_a_distribution') : t('stop_a_distribution')}</h2>
                        <p className="primary-form__description">{t('connector')} : {t(this.state.connector.fluidType)}-{this.state.connector.position}</p>
                        <p className="h1 terminal__please-wait">{t('proceed_with_disconnection')}</p>
                        <hr className="form-divider form-divider--big" />
                        <div className="primary-form__buttons primary-form__buttons--center ">
                            <button type="button" onClick={() => this.onChargeMessageClose()} className="vnf-btn vnf-btn-secondary vnf-btn-m vnf-btn-outline vnf-btn-bleu-a3">{t('close')}</button>
                        </div>
                    </div>)}
                </Modal>
            </LoadingOverlay>
        )
    }
}

/**
 * Renders the layout of connectors in the terminal modal.
 *
 * @param {Object} props - The component props.
 * @param {Array} props.connectors - The list of connectors.
 * @param {Object} props.terminal - The terminal object.
 * @param {Function} props.onToggle - The function to toggle the connector.
 * @param {Function} props.onReportUnvailable - The function to report the connector as unavailable.
 * @returns {JSX.Element} The rendered ConnectorLayout component.
 */
const ConnectorLayout = ({ connectors, terminal, onToggle, onReportUnvailable }) => {
    if (!connectors) {
        return <></>
    }
    const maxCol = getMax(c => c.col)(connectors) + 1
    const maxRow = getMax(c => c.row)(connectors) + 1
    const matrixSize = maxCol * maxRow

    if (isNaN(matrixSize)) {
        return <></>
    }

    const connectorsMatrix = Array(matrixSize).fill(null).map((_, i) => {
        const col = (i % maxCol)
        const row = Math.floor(i / maxCol);

        return {
            col,
            row,
            elem: connectors.find(e => e.col === col && e.row === row)
        }
    }).reduce((matrix, cur) => {
        const { col, row, elem } = cur

        if (col === 0) {
            matrix.push([])
        }

        matrix[row].push({
            col,
            row,
            elem
        })

        return matrix
    }, [])

    return (
        <ul className={`connector-list connector-list__col-${maxCol} connector-list__row-${maxRow}`}>
            {connectorsMatrix.flat().map((matrix, index) => {
                const { elem: connector } = matrix
                if (connector) {
                    return <Connector
                        index={connector.cpoConnectorId}
                        key={`${connector.cpoConnectorId}:${connector.label}::${connector.id}`}
                        connector={connector}
                        offline={!terminal.active}
                        onToggle={onToggle}
                        onReportUnvailable={onReportUnvailable} />
                }
                return <EmptyConnector key={`empty-connector-${index}`} />
            })}
        </ul>
    )
}

/**
 * Renders a Connector component.
 *
 * @param {Object} props - The component props.
 * @param {Object} props.connector - The connector object.
 * @param {boolean} props.offline - Indicates if the connector is offline.
 * @param {Function} props.onToggle - The function to handle toggle switch.
 * @param {Function} props.onReportUnvailable - The function to handle reporting as unavailable.
 * @returns {JSX.Element} The rendered Connector component.
 */
const Connector = ({ connector, offline, onToggle, onReportUnvailable }) => {
    const [open, setOpen] = useState(false);
    const { t } = useTranslation();

    let canToggleSwitch = ["Available", "Preparing"].includes(connector.status);

    const running_transactions = connector.transactions.filter(transaction => ["Charging", "Pending"].includes(transaction.status));

    const account = JSON.parse(localStorage.getItem('account'));
    const role = account?.roles?.filter(role => role !== 'ROLE_USER')[0] || [];

    if (!canToggleSwitch && running_transactions.length > 0) {
        const id = account.id;
        const { boat_owner_id, boat_armateur_id } = running_transactions.pop();
        canToggleSwitch = ['ROLE_ADMIN', 'ROLE_SUPER_ADMIN', 'ROLE_TECHNICIEN'].includes(role) || boat_owner_id === id || boat_armateur_id === id;
    }

    const displayAsUnavailable = () => {
        if (['ROLE_ADMIN', 'ROLE_SUPER_ADMIN', 'ROLE_TECHNICIEN'].includes(role)) return false
        return connector.disabled || offline
    }

    const ellipsis = (text, length = 10) => text.length > length ? text.substring(0, length) + '...' : text;

    return (<li className="connector-list__item">
        <div className="connector-list__header">
            <h4 className="connector-list__title">
                {ellipsis(connector?.label ?? '')} <span className="connector-list__connector-id">({connector?.cpoConnectorId})</span> - {t(connector.fluidType)}
                {/* {t(connector.fluidType)}-{connector.position} */}
                <span className="connector-list__type">{connector.capacity}</span>
            </h4>
            {offline ? (
                <span className={`connector-list__status connector-list__status--Unavailable`}>{t('Unavailable')}</span>
            ) : (
                <span className={`connector-list__status connector-list__status--${connector.status}`}>{t(connector.status)}</span>
            )}
            <button type="button" className={`connector-list__toggle${open ? ' open' : ''}`} onClick={() => setOpen(!open)}>
                <span className="sr-only">{open ? t('close') : t('open')}</span>
                <i className={`vnf-icons-icon-m-fleche-a-${open ? 'haut' : 'bas'}-outline`}></i>
            </button>
        </div>
        <SlideDown closed={!open} className="connector-list__slidedown">
            <div className="connector-list__body">
                <div className="connector-list__left">
                    {(!canToggleSwitch || offline) ? (t('unavailable_charge')) : (
                        <FormSwitch
                            id={'connector_on_off-' + connector.id}
                            label={t("Arrêter ou démarrer la distribution")}
                            value={connector.status === "Charging"}
                            handle={(e) => onToggle(e, connector)}
                            appearance="secondary"
                            labelOnOff={[t('Arrêter'), t('Démarrer')]} />
                    )}
                </div>
                <div className="connector-list__right">
                    {/* /*eslint no-mixed-operators: ["error", {"groups": [["&&", "||", "?:"]]}]* */}
                    {(displayAsUnavailable()) ? (t('unavailable')) :
                        (['ROLE_ADMIN', 'ROLE_SUPER_ADMIN', 'ROLE_TECHNICIEN'].includes(role)) ?
                            <FormCheckBox
                                id={'connector_unavailable-' + connector.id}
                                value={connector.status === "Unavailable"}
                                handle={(e) => onReportUnvailable(e, connector)}
                                appearance="secondary"
                                label={t('report_unavailable')} />
                            :
                            <></>
                    }
                </div>
            </div>
        </SlideDown>
    </li>)
}

/**
 * Renders an empty connector item.
 *
 * @param {Object} props - The component props.
 * @param {string} props.className - The additional class name for the connector item.
 * @returns {JSX.Element} The rendered empty connector item.
 */
const EmptyConnector = ({ className, ...props }) => {
    const finalClassNames = `connector-list__item connector-list__item__empty ${className}`
    return <li className={finalClassNames} {...props} ></li>
}


export default withToast(hoistStatics(withTranslation()(withRouter(TerminalModal)), TerminalModal));
