import CustomComponent from 'components/customComponent';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import hoistStatics from 'hoist-non-react-statics';
import LoadingOverlay from 'react-loading-overlay';
import { withToast } from '../../App';
import { DefaultLayout } from 'components/Layouts';
import IconButton from 'components/IconButton';
import Pagination from '../../components/Pagination';
import { TextFilter } from 'components/Filters';
import TerminalMap from './_TerminalMap';
import TerminalModal from './_TerminalModal';
import ScrollContainer from 'react-indiana-drag-scroll';
import moment from 'moment';

class Terminal extends CustomComponent {
    constructor(props) {
        super(props);
        this.state = {
            filter: {
                search: '',
                per_page: 15
            },
            terminal: {
                list: [],
            },
            // filteredTerminals: [],
            focusedTerminal: null,
            openTerminal: null,
            recap: {
                connectors: Array(3).fill('-'),
                terminals: Array(3).fill('-')
            },
            isActive: true,
            roles: [],
            currentPage: 1,
            pageTotal: 1,
            sortField: null,
            sortDirection: 'ASC',
        }
        this._isMounted = false;
        this.timeout = null;
    }

    componentDidMount () {
        this._isMounted = true;
        if (this.checkIsConnectedPWA()) {
            //Load localstorage value
            this.loadStorageValues(
                ['terminal'], 
                [this.state.terminal], 
                () => {this.setState({isActive: false}, () => this.getTerminals());}
            );
            this.setState({ role: this.loadRoles() });
            this._isMounted && this.getTerminals();
        }
    }

    async getConnectorCount() {
        this.setState({isActive: true})
        let url = '/connector/count';
        if (this.state.filter.search !== '') url += `?q=${this.state.filter.search}`;
        let data = await this.request(url, "GET");
        if (data && data.count) {
            this.setState(prevState =>({
                isActive: false,
                recap: {
                    ...prevState.recap,
                    connectors: [
                        data.count.Available || 0,
                        data.count.Occupied || 0,
                        data.count.Unavailable || 0
                    ],
                }
            }))
        }
    }

    async getTerminals (triggeredBySearch = false) {
        const page = triggeredBySearch ? 1 : this.state.currentPage;
        const limit = this.state.filter.per_page;
        let url = `/terminal/?limit=${limit}&page=${page}`;
        if (this.state.filter.search !== '') url += `&q=${this.state.filter.search}`
        const method = "GET";
        let data = await this.request(url, method);

        if (data && data.terminals) {
            this.setState(prevState => ({
                currentPage: page,
                terminal: {
                    ...prevState.terminal,
                    list: data.terminals,
                },
                isActive: false,
                pageTotal: Math.ceil(data.nbPages) || 1,
                recap: {
                    ...prevState.recap,
                    terminals: [
                        data.count.Online || 0,
                        data.count.Offline || 0,
                        data.count.Unknown || 0
                    ]
                }
            }), () => {
                // this.updateFilteredTerminals()
                localStorage.setItem('terminal', JSON.stringify(this.state.terminal));
            })

            this._isMounted && this.getConnectorCount();
        }
    }

    paginationOnClick = (e) => {
        const value = e.currentTarget.value
        if (+value !== +this.state.currentPage) {
            this.setState({currentPage: value}, () => {this.getTerminals()})
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
        if (this.timeout) clearTimeout(this.timeout)
    }


    handleSort = (field) => {
        this.setState({
            sortField: field,
            sortDirection: (field !== this.state.sortField || this.state.sortDirection === 'DESC') ? 'ASC' : 'DESC'
        }, () => this.getTerminals())
    }

    handleMarkerClick = (terminal) => {
        if (!this.state.focusedTerminal || this.state.focusedTerminal.id !== terminal.id) {
            this.setState({focusedTerminal: terminal})
        } else {
            this.setState({openTerminal: terminal})
        }
    }

    onFilterInputChange = (e) => {
        const term = e.target.value

        this.setState(prevState => ({
            filter: {
                ...prevState.filter,
                search: term
            },
        }), () => {
            if (this.timeout) clearTimeout(this.timeout)
            this.timeout = setTimeout(() => this.getTerminals(true), 500)
        })
    }

    onModalClose = () => {
        this.setState({openTerminal: null})
        this.getTerminals()
    }

    render () {
        const {t} = this.props

        return (<>
            <TerminalMap
                onMarkerClick={terminal => this.handleMarkerClick(terminal)}
                terminals={this.state.terminal.list}
                focusedTerminal={this.state.focusedTerminal}/>

            {this.state.openTerminal && (
                <TerminalModal
                    terminal={this.state.openTerminal}
                    onClose={this.onModalClose}/>
            )}

            <DefaultLayout>
                <LoadingOverlay active={this.state.isActive} classNamePrefix="loader_" spinner text={t('loading')}></LoadingOverlay>
                <h1 className="main-title visible-mobile">{t('terminals_state')}</h1>

                <div className="table-header__container">
                    <div className="row">
                        <div className="col-md-6 order-md-1">
                            <div className="table-header table-header--blue">
                                <div className="row">
                                    <div className="col-lg-6 terminal-recap__col">
                                        <h2 className="table-header__title table-header__title--center">{t('charging_points')}</h2>
                                        <ul className="terminal-recap">
                                            <li className="terminal-recap__item">
                                                <span className="terminal-recap__value terminal-recap__value--success">{this.state.recap.connectors[0]}</span>
                                                <span className="terminal-recap__label">{t('recap_available')}</span>
                                            </li>
                                            <li className="terminal-recap__item">
                                                <span className="terminal-recap__value terminal-recap__value--warning">{this.state.recap.connectors[1]}</span>
                                                <span className="terminal-recap__label">{t('recap_occupied')}</span>
                                            </li>
                                            <li className="terminal-recap__item">
                                                <span className="terminal-recap__value terminal-recap__value--error">{this.state.recap.connectors[2]}</span>
                                                <span className="terminal-recap__label">{t('recap_error')}</span>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="col-lg-6 terminal-recap__col">
                                        <h2 className="table-header__title table-header__title--center">{t('terminals')}</h2>
                                        <ul className="terminal-recap">
                                            <li className="terminal-recap__item">
                                                <span className="terminal-recap__value terminal-recap__value--success">{this.state.recap.terminals[0]}</span>
                                                <span className="terminal-recap__label">{t('recap_online')}</span>
                                            </li>
                                            <li className="terminal-recap__item">
                                                <span className="terminal-recap__value terminal-recap__value--warning">{this.state.recap.terminals[1]}</span>
                                                <span className="terminal-recap__label">{t('recap_offline')}</span>
                                            </li>
                                            <li className="terminal-recap__item">
                                                <span className="terminal-recap__value terminal-recap__value--error">{this.state.recap.terminals[2]}</span>
                                                <span className="terminal-recap__label">{t('recap_unknown')}</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 order-md-0">
                            <div className="table-header table-header--green">
                                <h2 className="table-header__title">{t('terminals_list')}</h2>
                                <TextFilter
                                    id="search"
                                    name="search"
                                    value={this.state.filter.search}
                                    label={t('filter_terminal_placeholder')}
                                    placeholder={t('filter_terminal_placeholder')}
                                    // handle={(e) => this.handleInputChange(e, null, 'filter', () => this.getTerminals())}/>
                                    handle={this.onFilterInputChange}/>
                            </div>
                        </div>
                    </div>
                </div>
                <ScrollContainer className="card-table__wrapper" hideScrollbars={false}>
                    <table className="card-table card-table--green">
                        <thead>
                            <tr>
                                <td></td>
                                <th>{t('status')}</th>
                                <th>{t('name')}</th>
                                <th>{t('place')}</th>
                                <th>{t('city')}</th>
                                <th>{t('entity')}</th>
                                {/* <td>{t('version')}</td> */}
                                <th>{t('actions')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.terminal.list.map(terminal => (
                                <tr key={terminal.id} className={(this.state.focusedTerminal && terminal.id === this.state.focusedTerminal.id) ? 'focused' : ''}>
                                    <td className="card-table__min-width card-table__show">
                                        <IconButton
                                            label={t('Show')}
                                            icon="vnf-icons-icon-m-oeil-outline"
                                            focusIcon="vnf-icons-icon-m-oeil-solid"
                                            color="bleu-a4"
                                            onClick={() => this.setState({openTerminal: terminal})}/>
                                    </td>
									{terminal.active ? (
										<td className={`card-table__status card-table__status--${terminal.status}`} data-tip={`${t('last_update')}: ${moment.utc(terminal.lastUpdate.date).format(t('DATE_HOURS_FORMAT'))}`}>{t(terminal.status)}</td>
									) : (
										<td className={`card-table__status card-table__status--Offline`} data-tip={`${t('last_update')}: ${moment.utc(terminal.lastUpdate.date).format(t('DATE_HOURS_FORMAT'))}`}>{t('Offline')}</td>
									)}
                                    <td data-label="Nom">{terminal.cpoTerminalId}</td>
                                    <td data-label="Lieu">{terminal.address}</td>
                                    <td data-label="Ville">{terminal.city}</td>
                                    <td data-label="Entité">{terminal.entity.name}</td>
                                    {/* <td data-label="Version">{terminal.version}</td> */}
                                    <td className="card-table__actions card-table__d-none card-table__min-width">
                                        <div className="card-table__actions-wrapper">
                                            <IconButton
                                                label={t('show_on_map')}
                                                showLabel
                                                icon={(this.state.focusedTerminal && terminal.id === this.state.focusedTerminal.id) ? "vnf-icons-icon-m-position-a-solid" : "vnf-icons-icon-m-position-a-outline"}
                                                focusIcon="vnf-icons-icon-m-position-a-solid"
                                                color="bleu-a4"
                                                onClick={() => this.setState({focusedTerminal: terminal})}/>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </ScrollContainer>
                {(this.state.pageTotal > 1 && this.state.terminal.list.length > 0) && (
                    <Pagination handleClick={this.paginationOnClick} pageCurrent={this.state.currentPage} pageTotal={this.state.pageTotal} pageDisplay={3} />
                )}
            </DefaultLayout>
        </>)
    }
}

export default withToast(hoistStatics(withTranslation()(withRouter(Terminal)), Terminal));